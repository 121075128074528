import React from "react";
import { useTranslation } from 'react-i18next';
import RotatingText from "../../DefaultPageComponents/RotateText/RotateText";
import RotatingImages from "../../DefaultPageComponents/rotateImg/RotateImg";

import './awards.css';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import nicLogo from './nic logo.png';
import founderLogo from './founder inst logo.png';
import ncatLogo from './ncat logo.png';
import pashaLogo from './pasha logo.png';

const Awards = () => {

    const { t } = useTranslation();

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    return (
        <>
            <section id="awards_section">
                <h1 id="awards_hdng">
                    {t('defaultPage_awards.main_heading')}

                </h1>

                {
                    checkResponsive()
                        ?
                        (
                            <div id="awards_div">

                                <MDBCard className="awards_card">
                                    <MDBRipple id="awards_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                        <div id="awards_card_img">
                                            <RotatingImages
                                                images={[
                                                    nicLogo,
                                                    founderLogo,
                                                    ncatLogo,
                                                    pashaLogo]}
                                                interval={3000} />
                                        </div>
                                        {/* <MDBCardImage style={{ height: '100%' }} fluid alt='...'>
                                            <RotatingImages
                                                images={[
                                                    techImg,
                                                    knowledgeImg,
                                                    facilitationImg,
                                                    dataImg,
                                                    consultImg]}
                                                interval={3000} />
                                        </MDBCardImage> */}
                                    </MDBRipple>
                                    {/* <MDBCardBody className="info_card_txt_div">
                                        <MDBCardTitle className="info_card_hdng">
                                            <RotatingText
                                                texts={[
                                                    t('defaultPage_why.why_card.tech.heading'),
                                                    t('defaultPage_why.why_card.knowledge.heading'),
                                                    t('defaultPage_why.why_card.facilitation.heading'),
                                                    t('defaultPage_why.why_card.data.heading'),
                                                    t('defaultPage_why.why_card.consultation.heading')
                                                ]}
                                                interval={3000}
                                            />
                                        </MDBCardTitle>
                                        <MDBCardText className="info_card_txt">
                                            <RotatingText
                                                texts={[
                                                    t('defaultPage_why.why_card.tech.text'),
                                                    t('defaultPage_why.why_card.knowledge.text'),
                                                    t('defaultPage_why.why_card.facilitation.text'),
                                                    t('defaultPage_why.why_card.data.text'),
                                                    t('defaultPage_why.why_card.consultation.text')
                                                ]}
                                                interval={3000}
                                            />
                                        </MDBCardText> 
                                     </MDBCardBody> */}
                                </MDBCard>

                            </div>
                        )
                        :
                        (<div id="awards_div">

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={nicLogo} fluid alt='...' />
                            </MDBCard >

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={founderLogo} fluid alt='...' />
                            </MDBCard>

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={ncatLogo} fluid alt='...' />
                            </MDBCard>

                            <MDBCard className="awards_card">
                                <MDBCardImage style={{ height: '100%' }} src={pashaLogo} fluid alt='...' />
                            </MDBCard>


                        </div >

                        )
                }



            </section >
        </>
    );
};

export default Awards;